var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "submenu", class: { "el-menu--collapse": _vm.isCollapse } },
    [
      _vm._l(_vm.menuData, function (menu, ind) {
        return [
          menu.children && menu.children.length
            ? _c(
                "el-submenu",
                {
                  key: ind,
                  staticClass: "father_item",
                  attrs: {
                    "active-text-color": "#0F6EFF",
                    index: `${menu.webUri}-${menu.code}`,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "top_menu",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", {
                        class: "iconfont icon" + _vm.setIcons(menu.webUri),
                      }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(menu.desc)),
                      ]),
                    ]
                  ),
                  _c("submenu", {
                    staticClass: "children_item",
                    attrs: { menuData: menu.children },
                  }),
                ],
                1
              )
            : [
                _c(
                  "el-menu-item",
                  { key: ind, attrs: { index: "/" + menu.webUri } },
                  [
                    _c("i", {
                      class: "iconfont icon" + _vm.setIcons(menu.webUri),
                    }),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(menu.desc)),
                    ]),
                  ]
                ),
              ],
        ]
      }),
      _c(
        "div",
        {
          staticClass: "content-submenu_icon",
          style: { width: _vm.isCollapse ? "54px" : "" },
        },
        [
          _c(
            "div",
            { staticClass: "submenu_icon", on: { click: _vm.changeState } },
            [
              _vm.isCollapse
                ? _c("svg-icon", {
                    attrs: {
                      className: "menu-icon",
                      "icon-class": "menu-icon",
                    },
                  })
                : _c("svg-icon", {
                    attrs: {
                      className: "menu-icon expand",
                      "icon-class": "menu-icon",
                    },
                  }),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }