<template>
  <div class="submenu" :class="{ 'el-menu--collapse': isCollapse }">
    <template v-for="(menu, ind) in menuData">
      <el-submenu
        active-text-color="#0F6EFF"
        v-if="menu.children && menu.children.length"
        :index="`${menu.webUri}-${menu.code}`"
        :key="ind"
        class="father_item"
      >
        <div slot="title" class="top_menu">
          <i :class="'iconfont icon' + setIcons(menu.webUri)"></i>
          <span slot="title">{{ menu.desc }}</span>
        </div>
        <submenu :menuData="menu.children" class="children_item"></submenu>
      </el-submenu>
      <template v-else>
        <el-menu-item :index="'/' + menu.webUri" :key="ind">
          <i :class="'iconfont icon' + setIcons(menu.webUri)"></i>
          <span slot="title">{{ menu.desc }}</span>
        </el-menu-item>
      </template>
    </template>
    <div class="content-submenu_icon" :style="{ width: isCollapse ? '54px' : '' }">
      <div class="submenu_icon" @click="changeState">
        <svg-icon v-if="isCollapse" className="menu-icon" icon-class="menu-icon"></svg-icon>
        <!-- <img
          v-if="isCollapse"
          src="./img/icon-menu-fold.png"
          style="transform: rotate(180deg); margin-right: 16px"
          @click="changeState"
        /> -->
        <svg-icon v-else className="menu-icon expand" icon-class="menu-icon"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["menuData", "isCollapse"],
  name: "submenu",
  methods: {
    setIcons(ico) {
      if (ico == "ParkSystem") {
        return " icon-cechangli-copy";
      } else if (ico == "acbOverview" || ico == "bacbOverview") {
        return " icon-gailan";
      } else if (ico == "collectorManage") {
        return " icon-ico-renyuanguanli";
      } else if (ico == "equipmentManage") {
        return " icon-shebeiguanli";
      } else if (ico == "businessRecord") {
        return " icon-yewujilu";
      } else if (ico == "exceptionManage") {
        return " icon-exceptionManage";
      } else if (ico == "dataAlarm") {
        return "-baojing1";
      } else if (ico == "staggered1") {
        return "ParkControl";
      } else if (ico == "shortMessageManage") {
        return "";
      } else if (ico == "deptManage") {
        return "shortMessageManage";
      } else if (ico == "alarmRecord") {
        return "shujubaojing";
      } else if (ico == "serviceManage") {
        return "";
      } else if (ico == "remoteOpsManage") {
        return "yuanchengyunweiguanli";
      } else if (ico == "screen") {
        return "youdaopingguanli";
      } else if (ico == "program") {
        return "jiemuguanli";
      } else if (ico == "material") {
        return "sucaiguanli";
      } else if (ico == "equipmentStatistics") {
        return "shebeifenxi";
      } else if (ico == "Coupon") {
        return "youhuiquanguanli";
      } else if (ico == "yardDiscount1") {
        return "chechangzhekou";
      } else if (ico == "application") {
        return "yingyong";
      } else if (ico == "platformConfiguration") {
        return "zhifupeizhi";
      } else if (ico == "systemLog") {
        return "rizhi";
      } else if (ico == "hotBusiness") {
        return "";
      } else if (ico == "homeConfig") {
        return "";
      } else if (ico == "systemManage") {
        return "xiaoxiguanli";
      } else if (ico == "systemConfig") {
        return "xitongshezhi";
      } else if (ico == "ParkFilingConf") {
        return "chechangbeian";
      } else if (ico == "exceptionOrder") {
        return "operatorMainPart";
      } else if (ico == "arrearsManagement") {
        return "orderManager";
      } else if (ico == "deviceManage") {
        return " icon-ico-wupinguanli";
      } else if (ico == "equipmentHold") {
        return " icon-ico-yunweiguanli";
      } else if (ico == "businessAccretion") {
        return "xitongshezhi";
        // eslint-disable-next-line brace-style
      }
      // else if (ico == "payRecordTotal") {
      //   return "BusinessConf";
      // }
      else if (ico == "invoiceElc") {
        return "jiemuguanli";
      } else if (ico == "invoiceManage") {
        return "clean";
      } else if (ico == "operatorInfoManage") {
        return "operatorMainPart";
      } else if (ico == "dtsManager") {
        return "operatorMainPart";
      } else if (ico == "mechantManage") {
        return "operatorMainPart";
      } else if (ico == "storeMainPart") {
        return "FeeManagerConf";
      } else if (ico == "operatorAdministratorsManage") {
        return "";
      } else if (ico == "operatorInfoManage") {
        return "";
      } else if (ico == "java_home") {
        return "home";
      } else if (ico == "java_parkingAnalysisMain") {
        return "parkingAnalysisMain";
      } else if (ico == "java_statistics3") {
        return "statistics3";
      } else if (ico == "java_memberStatistics") {
        return "huiyuan";
      } else if (ico == "pdaTicketConfig") {
        return "sucaiguanli";
      } else if (ico == "java_acbOverview") {
        return " icon-gailan";
      } else if (ico == "ParkConf") {
        return " icon-cechangli-copy";
      } else if (ico == "Record") {
        return " icon-ico-jiluchaxun";
      } else if (ico == "FeeManagerConf") {
        return " icon-ico-renyuanguanli";
      } else if (ico == "BusinessConf") {
        return " icon-ico-yewuguanli";
      } else if (ico == "EquipmentConf") {
        return " icon-ico-shebeiguanli";
      } else {
        return ico;
      }
    },
    changeState() {
      this.$emit("changeState");
    },
  },
};
</script>
<style lang="stylus" scoped>
.el-menu--collapse .submenu_icon {
  text-align: center;
}
.submenu_icon{
	text-align: right;
	position: relative;
	background: #fff;
	// padding-top: 10px;
	height: 48px;
	box-sizing: border-box;
  .menu-icon {
    transform: rotate(180deg);
    width: 24px;
    height: 24px
    color: #f5f6f7;
    &.expand {
      transform: rotate(0deg);
      margin-right: 12px;
    }
    &:hover {
      color: #E6E8EB;
    }
  }
	>i.iconzhankai,i.iconshouqi {
		font-size: 18px;
		cursor: pointer;
		color:#57595e;
		&:hover{
			color: #1D70FF;
		}
	}
}
::v-deep .submenu.children_item {
  .el-menu-item {
    padding-left: 30px !important
  }
}
// ::v-deep .el-menu-item {
//   padding-left: 14px !important
//   padding-right: 12px
// }
// ::v-deep .father_item.el-submenu {
//   .el-submenu__title {
//     padding-left: 14px !important
//     padding-right: 12px
//     span {
//       height: 22px
//       font-family: PingFang SC, PingFang SC;
//       font-weight: 400;
//       font-size: 14px;
//       color: #4E5969;
//       text-align: left;
//       font-style: normal;
//       text-transform: none;
//     }
//   }
// }

.submenu_icon{
	display:  none;
	// margin-top: 10px;
	// padding-bottom: 30px;
	line-height: 54px;
}
.scrollWrapper{
	>.el-menu>.submenu>.content-submenu_icon>.submenu_icon{
		display:  block;
	}
}
.scrollWrapper{
	>.el-menu>.submenu:last-child {
		padding-bottom: 40px;
	}
}
.content-submenu_icon {
	width: 220px;
	height: 40px;
	position: fixed;
	bottom: 0;
	left: 0;
	// background: #303546;
}
.submenu_icon img{
  width: 24px;
  height:24px;
  cursor pointer
  color:#F5F6F7
  float: right;
  padding-right: 12px;
  margin-top:8px;
  border-radius: 2px;
  }
</style>
