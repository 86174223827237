<template>
  <div class="header">
    <!--头部-->
    <div id="header">
      <div
        @click="$router.push('/portal')"
        style="cursor: pointer; display: inline-block; display: flex; align-items: center"
      >
        <i
          v-if="!logoUrl"
          style="font-size: 18px; color: #527bff"
          class="iconfont iconACSlogo"
          @click="$router.push('/portal')"
        ></i>
        <img v-if="logoUrl" :src="logoUrl" style="max-height: 32px; margin-right: 6px" alt="" />
        <span class="header-title">{{ platformName ? platformName : "智慧泊车管理平台" }}</span>
      </div>
      <div class="menu">
        <div class="system icon_right" @click="showUnread">
          <svg-icon icon-class="navbar-right"></svg-icon>
          <span class="unreadNum" v-show="infoCount > 0"></span>
          <transition name="fade">
            <systemDialog class="systemDialog" v-if="systemDialogShow" :unreadData="unreadData" />
          </transition>
        </div>
        <div class="messCall icon_right" @click="downListShowGetHttp">
          <svg-icon icon-class="down-load"></svg-icon>
        </div>
        <!-- <span
          style="
            display: inline-block;
            width: 40px;
            text-align: center;
            transform: translate(0, 2px);
          "
          @click="downListShowGetHttp"
        >
          <el-badge :value="downListNum" class="icon-xiaza-item" v-if="downListNum > 0">
            <el-button type="text"><i class="iconfont iconxiazai1"></i></el-button>
          </el-badge>
          <el-button type="text" v-else><i class="iconfont iconxiazai1"></i></el-button>
        </span> -->
        <div class="touxiang">
          <div class="userImg"></div>
        </div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link username" style="cursor: pointer; color: #031633">
            {{ $store.state.username }}&nbsp;<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <span
              @click="
                $refs.ruleForm && $refs.ruleForm.resetFields();
                dialogFormVisible = true;
              "
              ><el-dropdown-item
                ><i class="el-icon-edit"></i>&nbsp;&nbsp;修改密码</el-dropdown-item
              ></span
            >
            <span @click="logout"
              ><el-dropdown-item
                ><i class="el-icon-circle-close"></i>&nbsp;&nbsp;退出</el-dropdown-item
              ></span
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dialog
          width="450px"
          :append-to-body="true"
          title="修改密码"
          :visible.sync="dialogFormVisible"
        >
          <el-form :model="form" label-position="right" :rules="rule" ref="ruleForm">
            <el-form-item label="原密码" label-width="80px" prop="password">
              <el-input
                v-model.trim="form.password"
                auto-complete="off"
                placeholder="请输入旧密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" label-width="80px" prop="newPassword">
              <el-input
                v-model.trim="form.newPassword"
                auto-complete="off"
                placeholder="请输入新密码"
                type="password"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="modifyPwd">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="downList" v-show="downListShow">
      <h3>下载中心</h3>
      <div class="downListCon">
        <ul>
          <li v-for="item in downList" :key="item.fileName">
            <span class="downName" v-text="item.fileName">20181213122334.xls</span>
            <span>..................................</span>
            <span v-if="item.status == 0">准备中...</span>
            <a :href="item.fileUrl" v-else-if="item.status == 1">下载</a>
            <span v-else>失败</span>
          </li>
        </ul>
        <el-button type="primary" @click="downListHidden">收起</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { jsMd5, exportExcelNew, dateFormat, getDt } from "@/common/js/public.js";
import systemDialog from "../systemDialog/systemDialog";
import * as utils from "@/common/js/utils.js";
import Vue from "vue";
export default {
  name: "headerELE",

  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
        // } else if (/^[\w|@]{6,15}$/.test(value)) {
      } else if (/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        callback();
      } else {
        // return callback(new Error("密码只能是数字、字母、特殊字符或下划线且长度在6-15位"));
        return callback(new Error("大写字母+小写字母+数字+特殊字符，至少8位"));
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
        // } else if (/^[\w|@]{6,15}$/.test(value)) {
      } else if (/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
        callback();
      } else {
        // return callback(new Error("密码只能是数字、字母、特殊字符或下划线且长度在6-15位"));
        return callback(new Error("大写字母+小写字母+数字+特殊字符，至少8位"));
      }
    };
    return {
      logoUrl: sessionStorage.logoUrl,
      platformName: sessionStorage.platformName,
      timmer: null,
      timerNotification: null,
      timeCount: 1,
      time1: "", // 通知消息出现弹框的时间
      time2: "", // 监控车辆报警消息出现弹框的时间
      systemDialogShow: false,
      unreadData: [],
      infoCount: 0,
      downList: [],
      downListShow: false,
      downListNum: "",
      userName: "xxx",
      dialogFormVisible: false,
      rule: {
        // oldPwd: [
        //   { validator: validateOldPass, trigger: 'blur' }
        // ],
        password: [{ validator: validatePass, trigger: "blur" }],
        newPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      form: {
        password: "",
        newPassword: "",
      },
    };
  },
  components: {
    systemDialog,
  },
  mounted() {
    // sessionStorage.loginTime = dateFormat(new Date());
    if (sessionStorage.userId) {
      this.timedTask();
    }
    // 定时任务
    this.timmer = setInterval(() => {
      if (sessionStorage.userId) {
        this.timedTask();
      } else {
        clearInterval(this.timmer);
        clearInterval(this.timerNotification);
      }
    }, 60000);
    this.timerNotification = setInterval(() => {
      // sessionStorage.loginTime = dateFormat(new Date());
      if (this.timeCount == 1) {
        this.infoAlarmRecord();
        this.timeCount = 2;
      } else if (this.timeCount == 2) {
        this.vehicleAlarmRecord();
        this.timeCount = 1;
      }
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timmer);
    clearInterval(this.timerNotification);
    if (this.notify) {
      this.notify.visible = false;
    }
  },
  methods: {
    timedTask() {
      this.$axios
        .get("acb/2.0/sysmgMsgConf/recordNumberByGroup", {
          data: {
            userId: sessionStorage.userId,
            unread: 0,
          },
        })
        .then((res) => {
          // this.unreadData = [];
          // this.unreadData = res.value || [];
          // this.infoCount = 0;
          if (res.value && res.value.length > 0) {
            // 处理设备报警消息权限
            // if (!isMenu("/equipmentAlarmInfo")) {
            //   this.unreadData = this.unreadData.filter(item => item.msgType !== 3) || [];
            // }
            // if (!isMenu("/vehicleAlarmInfo")) {
            //   this.unreadData = this.unreadData.filter(item => item.msgType !== 2) || [];
            // }
            // if (!isMenu("/systemNotice")) {
            //   this.unreadData = this.unreadData.filter(item => item.msgType !== 0) || [];
            // }
            var infoCount = 0;
            res.value.map((v) => {
              if (v.recordNumber) {
                infoCount = v.recordNumber + infoCount;
              }
            });
            // // notification
            // // 通知开关开并且有新消息时
            // if (sessionStorage.blockNotification == 0 && infoCount > 0) {
            //   var type = 0;
            //   // 找出一个需要弹窗对消息，最好是后端接口返回，否则用下面的workaround
            //   res.value.some((value, index, _arr) => {
            //     if (value.recordNumber > 0) {
            //       // 如果没有老的数据显示弹窗
            //       if (!this.unreadData || this.unreadData.length <= 0) {
            //         if (value.msgType == 0) {
            //           type = 1;
            //         } else if (value.msgType == 2) {
            //           type = 2;
            //         }
            //         return true;
            //       } else {
            //         // 有老的数据且老数据 未读消息数量比新数据小显示弹窗
            //         this.unreadData.some((value1, index1, _arr1) => {
            //           if (value1.msgType == value.msgType && value1.recordNumber < value.recordNumber) {
            //             if (value1.msgType == 0) {
            //               type = 1;
            //             } else if (value1.msgType == 2) {
            //               type = 2;
            //             }
            //             return true;
            //           }
            //         });
            //       }
            //     }
            //   })
            //   if (type != 0) {
            //     // 确定需要弹窗，type是需要弹窗的类型
            //     sessionStorage.notificationShowing = 1;
            //     let options = {
            //       type: type,
            //       content:
            //         type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息",
            //       clickSure: () => {
            //         this.getUnreadData(type);
            //       },
            //     };
            //     if (!this.notify) {
            //       // 如果没有通知过，加载通知
            //       this.notify = this.$notification(options);
            //     } else {
            //       // 如果通知过，修改通知属性显示最新的需要弹窗的类型
            //       this.notify.type = type;
            //       this.notify.content =
            //         type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息";
            //       this.notify.clickSure = () => {
            //         this.getUnreadData(type);
            //       };
            //       Vue.nextTick(() => {
            //         this.notify.visible = true;
            //       });
            //     }
            //   }
            // }
            // 最后把新数据赋值给老数据
            this.unreadData = res.value || [];
            this.infoCount = infoCount;
          }
        });
    },

    getUnreadData(type) {
      sessionStorage.addTab = 1;
      let data = {};
      if (type == 1) {
        data = {
          desc: "通知消息",
          webUri: "systemNotice",
        };
      } else if (type == 2) {
        data = {
          desc: "监控车辆报警消息",
          webUri: "vehicleAlarmInfo",
        };
      }
      let path = "SYSTEM_MANAGEMENT";
      const portalMenu = utils.findPortalMenu(this.$store.state.menu.authorityMenu, path);
      this.$store.dispatch("menu/CHANGE_MENU", portalMenu);
      this.$router.push(data.webUri);
    },
    // 车辆监控报警
    vehicleAlarmRecord() {
      // 点击小铃铛重新获取数据
      let opt = {
        url: "/acb/2.0/sysmgMsgConf/vehicleAlarmRecord",
        method: "get",
        data: {
          userId: sessionStorage.userId,
          carId: "",
          page: 1,
          pageSize: 15,
          startTime: sessionStorage.time2 || sessionStorage.loginTime,
        },
        success: (res) => {
          let that = this;
          if (res.state == 0) {
            let tableData = res.value.list;
            if (tableData[0].unread === 0) {
              // notification
              // 通知开关开并且有新消息时
              if (sessionStorage.blockNotification == 0) {
                var type = 2;
                // 确定需要弹窗，type是需要弹窗的类型
                sessionStorage.notificationShowing = 1;
                let options = {
                  type: type,
                  content: type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息",
                  clickSure: () => {
                    that.getUnreadData(type);
                  },
                };
                if (!that.notify) {
                  // 如果没有通知过，加载通知
                  that.notify = this.$notification(options);
                } else {
                  // 如果通知过，修改通知属性显示最新的需要弹窗的类型
                  that.notify.type = type;
                  that.notify.content = type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息";
                  that.notify.clickSure = () => {
                    that.getUnreadData(type);
                  };
                  Vue.nextTick(() => {
                    that.notify.visible = true;
                  });
                }
              }
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          sessionStorage.time2 = dateFormat(new Date());
        },
      };
      getDt(opt);
    },
    // 车辆监控报警
    infoAlarmRecord() {
      // 点击小铃铛重新获取数据
      let opt = {
        url: "/acb/2.0/sysmgMsgConf/recordlist",
        method: "get",
        data: {
          userId: sessionStorage.userId,
          msgType: 0,
          page: 1,
          pageSize: 10,
          startTime: sessionStorage.time1 || sessionStorage.loginTime,
        },
        success: (res) => {
          let that = this;
          if (!res.value) return;
          if (res.state == 0) {
            let tableData = res.value.list || [];
            if (tableData[0].unread === 0) {
              // notification
              // 通知开关开并且有新消息时
              if (sessionStorage.blockNotification == 0) {
                var type = 1;
                // 确定需要弹窗，type是需要弹窗的类型
                sessionStorage.notificationShowing = 1;
                let options = {
                  type: type,
                  content: type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息",
                  clickSure: () => {
                    that.getUnreadData(type);
                  },
                };
                if (!that.notify) {
                  // 如果没有通知过，加载通知
                  that.notify = that.$notification(options);
                } else {
                  // 如果通知过，修改通知属性显示最新的需要弹窗的类型
                  that.notify.type = type;
                  that.notify.content = type == 1 ? "您有新的通知消息" : "您有新的监控车辆报警消息";
                  that.notify.clickSure = () => {
                    that.getUnreadData(type);
                  };
                  Vue.nextTick(() => {
                    that.notify.visible = true;
                  });
                }
              }
            }
          }
          sessionStorage.time1 = dateFormat(new Date());
        },
      };
      getDt(opt);
    },

    showUnread() {
      this.systemDialogShow = !this.systemDialogShow;
    },
    downListHidden() {
      this.downListShow = false;
      this.downListNum = 0;
    },
    getExportFileList() {
      this.$axios.get("/acb/2.0/excelExportRecord/list").then((res) => {
        if (res.value && res.value.list.length > 0) {
          let data = res.value.list;
          if (window.location.origin.indexOf("https://") != -1) {
            data.forEach((item) => {
              item.fileUrl = item.fileUrl.replace("http://", "https://");
            });
          }
          this.downList = data;
        }
      });
    },
    downListShowGetHttp() {
      this.downListShow = true;
      this.getExportFileList();
      this.$axios
        .get("/acb/2.0/excelExportRecord/count", {
          data: {
            userId: sessionStorage.userId,
          },
        })
        .then((res) => {
          let num = res.value.total;
          sessionStorage.downNum = num;
        });
      this.downListNum = 0;
    },
    // 导出
    exportFile() {
      exportExcelNew("/acb/2.0/financeStatistic/report/export", {
        dateType: "day",
        startTime: this.formInline.payTime,
        endTime: this.formInline.payTime,
      });
    },
    logout() {
      this.$confirm("是否退出?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let opt = {
            url: "/acb/2.0/systems/login/logout",
            method: "post",
            data: {
              sessionId: sessionStorage.token,
            },
            success: (res) => {
              this.$message({
                type: "success",
                message: "退出成功!",
              });
              sessionStorage.clear();
              this.$router.push("/");
            },
          };
          this.$request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    modifyPwd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let d = { ...this.form };
          d.password = jsMd5(sessionStorage.account, jsMd5(sessionStorage.account, d.password));
          d.newPassword = jsMd5(sessionStorage.account, d.newPassword);
          let opt = {
            method: "post",
            url: "/acb/2.0/systems/login/modifyPassword",
            data: d,
            success: (res) => {
              if (res.state == 0) {
                this.$message({
                  type: "info",
                  message: "密码修改成功，请重新登录",
                });
                this.dialogFormVisible = false;
                this.$router.replace("/");
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            },
          };
          this.$request(opt);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.header{
  position fixed
  width: 100%
  top: 0
  z-index 10
}
#header
    .menu
      float: right;
      font-family: "PingFang";
      font-size: 14px;
      position relative
      .systemDialog
        position absolute
        top 50px
        right -50px
        z-index 9999
      .el-dropdown-link
        color: #fff;
        .el-icon-caret-bottom
          font-size: 12px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: #fff;
    color: #000;
    font-size: 20px;
    letter-spacing: 0;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E6EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title {
        display:inline-block;
        color: #1D2129;
        font-size: 18px;
        font-weight: 500;
        font-family: "PingFang";
        transform: none
      }
</style>
<style scoped lang="stylus">
.downList {
  width: 500px;
  height: 500px;
  overflow: hidden;
  border: 1px solid #ddd;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background: #fff;
  z-index: 2006;
}

.downList h3 {
  line-height: 40px;
  padding-left: 20px;
  background: #324057;
  font-size: 14px;
  color: #fff;
}

.downList .downListCon {
  padding: 30px;
  height: 400px;
  overflow: hidden;
  text-align: center;
}

.downList .downListCon ul {
  overflow: auto;
  height: 95%;
  width: 100%;
  text-align: left;
}

.downList .downListCon ul li {
  line-height: 30px;
}
.downList .downListCon ul li span,
.downList .downListCon ul li a {
  display: inline-block;
  vertical-align: middle;
}
.downList .downListCon ul li .downName {
  /* max-width: 230px; */
  overflow: hidden;
  white-space: nowrap;
}
.downList .downListCon ul li a {
  color: #0f6eff;
}
.iconxiazai1,
.iconxiaoxi1 {
  font-size: 20px;
}
.iconxiazai1:hover,
.iconxiaoxi1:hover {
  color: #0f6eff;
}
.icon-xiaza-item >>> .el-badge__content {
  top: 12px;
  border: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in below version 2.1.8 */ {
  opacity: 0;
}

.menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s linear;
  margin-left: 0;
  z-index: 1;
}
.menu .icon_right
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 16px;
  color: #f5f6f7;
  &:hover
    color: #E6E8EB;
.icon_right svg {
  width: 100%;
  height: 100%;
}
.leftMenu {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.leftMenu::-webkit-scrollbar {
  display: none;
}
.system {
  position: relative;
}
.unreadNum {
  position: absolute;
  display: block;
  top: 6px;
  right: 7px;
  line-height: 16px;
  text-align: center;
  width: 4px;
  height: 4px;
  background: #ff0000;
  border-radius: 50%;
  color: #fff;
}
.userImg {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background: url(./img/userImg.png);
  background-size: 100% 100%;
}
.touxiang {
  margin-right: 8px;
}
.message {
  width: 32px;
  height: 32px;
  background: #f2f3f5;
  border-radius: 24px;
  opacity: 1;
}
</style>
