<template>
  <div class="menu">
    <!-- <div
      class="switchButton"
      :class="{ 'el-icon-arrow-left': !isCollapse, 'el-icon-arrow-right': isCollapse }"
      @click="collapseClick"
    ></div> -->
    <div class="scrollWrapper">
      <div
        class="home_icon"
        v-if="['/home', '/java_home'].includes($route.path)"
        @click="$router.push('/portal')"
      >
        <img src="./img/home.png" alt="" />
      </div>
      <el-menu
        :collapse-transition="false"
        :default-active="activeMenu"
        :router="false"
        class="el-menu-vertical-demo"
        @select="menuClick"
        text-color="#4F5C70"
        :collapse="isCollapse"
        unique-opened
        active-text-color="#0F6EFF"
      >
        <SubMenu
          :menuData="currentPortalMenu"
          :isCollapse="isCollapse"
          @changeState="collapseClick"
        ></SubMenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SubMenu from "./submenu";
import * as utils from "@/common/js/utils";

export default {
  name: "HelloWorld",
  components: {
    SubMenu,
  },
  data() {
    return {
      activeMenu: "",
      isCollapse: false,
    };
  },
  computed: {
    ...mapState({
      currentPortalMenu: (state) => state.menu.currentPortalMenu,
    }),
  },
  watch: {
    $route: {
      handler(ne) {
        if (["home", "java_home"].includes(ne.name)) {
          this.isCollapse = true;
          this.$store.commit("SET_COLLAPSE", true);
        } else {
          this.isCollapse = false;
        }
        this.activeMenu = utils.getPath(ne.name);
      },
      immediate: true,
    },
  },
  props: {
    menuData: {
      default() {
        return [];
      },
    },
  },
  methods: {
    collapseClick() {
      if (["/home", "/java_home"].includes(this.$route.path)) {
        return;
      }
      this.isCollapse = !this.isCollapse;
      this.$store.commit("SET_COLLAPSE", this.isCollapse);
    },
    menuClick(index, indexPath) {
      let route = utils.ifDupl(this.$store.state.tabs.currentTabs, index);
      if (route) {
        this.$router.push(route.children[route.children.length - 1]);
      } else {
        this.$router.push(index);
      }
    },
  },
  mounted() {
    console.log(this.currentPortalMenu);
  },
};
</script>
<style scoped lang="stylus">
::v-deep .submenu.el-menu--collapse {
  .father_item.el-submenu.is-active {
    padding: 0 8px
    background-color: #0F6EFF
    .iconfont {
      color: #FFF !important;
    }
  }
}
::v-deep .father_item.el-submenu.is-opened {
  .el-submenu__title {
    color: #0F6EFF !important;
    .iconfont {
      color: #0F6EFF !important;
    }
  }
  .submenu.children_item {
    .el-submenu__title {
      padding-left: 30px !important;
      color: #4f5c70 !important;
      }
    .father_item.el-submenu.is-opened {
      .el-submenu__title {
        color: #0F6EFF !important;
      }
    }
  }
}
.switchButton
  width: 15px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  right: -15px;
  top: 200px;
  background: rgba(50, 55, 66, 1);
  color: white;
  border-radius: 0 15px 15px 0;
  z-index: 10;
  cursor: pointer;


.menu
  background: rgb(50, 55, 66);
  position: relative;
  height: 100%;

.scrollWrapper
  height: 100%
  overflow auto;
  background: #fff;
  .home_icon {
    width: 54px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    img {
      width: 14px;
      height: 14px;

    }
  }
.scrollWrapper::-webkit-scrollbar {
  display: none;
}
.el-menu-vertical-demo >>>.iconfont{
  padding-right:16px;
  font-size: 18px;
  color: #4F5C70;
}

.el-menu-vertical-demo >>> .el-menu-item{
  height 40px;
  line-height 40px;
  border-radius: 2px;
  padding-left: 12px !important;
  margin-bottom: 8px;
}
.el-menu-vertical-demo >>> .el-menu-item:nth-of-type(1){
  margin: 4px 0 8px;
}
.el-menu-vertical-demo >>> .el-submenu__title {
    height: 40px;
    line-height: 40px;
    padding: 0 12px !important;

}
.el-menu-vertical-demo.el-menu--collapse >>> .el-submenu__title {
  padding: 0px !important;
}
.el-menu-vertical-demo >>> .el-submenu__title i{
  font-size: 18px;
}
.el-menu-vertical-demo >>> .el-submenu__icon-arrow{
  font-size: 14px !important;
  right: 12px;
  color: #4F5C70;
}
.el-menu-vertical-demo >>>.el-menu-item.is-active{
  background  #e7f0ff;
  color: #0F6EFF !important
  position: relative;
  &:hover span{
    color:  #0F6EFF;
  }
}
.el-menu-vertical-demo >>> .el-submenu
  margin-bottom: 8px;
.el-menu-vertical-demo >>>.el-menu-item.is-active .iconfont
  color #0F6EFF

.el-menu-vertical-demo >>>.el-submenu .el-menu-item
  margin-bottom: 4px;
  min-width inherit
.el-menu-vertical-demo >>> .el-submenu .el-menu
  padding 0
  background: #F9FAFA;
</style>
<style lang="stylus">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 204px;
  border: 0;
  padding: 0 8px;
}
.el-menu--collapse {
  width: 52px;
}
.el-menu--vertical .el-menu-item.is-active {
  color: #0F6EFF !important;
  background-color: #ECF3FF;
}
.el-menu--vertical {
  .el-menu.el-menu--popup.el-menu--popup-right-start {
    padding: 0;
    margin: 0;
    min-width: 164px;
    .el-menu-item {
      height: 40px;
      line-height: 34px;
      margin: 0 8px 8px !important;
      padding: 0 !important;
      text-align: center;
      &:hover {
        background: rgba(245,246,247,0.5);
        span {
          color: #4F5C70;
        }
      }
    }
    .father_item.el-submenu.is-active.is-opened {
      .el-submenu__title {
        color: #0F6EFF !important;
      }

    }
  }
}
.el-menu--collapse .el-submenu__title {
  padding-left: 0 !important;
  padding: 0;
  text-align: center;
  margin-bottom: 8px;
}
.el-menu--collapse .iconfont {
  padding-right: 0 !important;
}

.el-menu-item .el-tooltip {
  padding: 0 !important;
  text-align: center;
}
.el-menu {
  border: 0;
  height: 100%;
}

.el-menu-item:hover,
.el-submenu__title:hover {
  background: transparent;
}
.el-menu-item:hover span,
.el-submenu__title:hover span {
  color: #165dff;
}
.el-menu-item:hover .iconfont,
.el-submenu__title:hover .iconfont {
  color: #165dff;
}
.el-menu-item:hover .iconfont,
.el-submenu__title:hover i {
  color: #165dff;
}
</style>
