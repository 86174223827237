<template>
  <div class="index height100">
    <el-container class="height100">
      <el-header :class="{ noHeight: noHeihgt }" style="height: 46px; padding: 0">
        <Header></Header>
      </el-header>
      <el-container class="bottomC">
        <div class="aside">
          <SideBar></SideBar>
        </div>
        <el-main class="main-bgc" style="position: relative">
          <Tabs></Tabs>
          <keep-alive :include="$store.getters['tabs/cacheTabs']">
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import SideBar from "@/components/sidebar";
import Tabs from "@/components/tabs";
import Header from "@/components/header";
import * as utils from "@/common/js/utils.js";
export default {
  name: "index",
  components: {
    SideBar,
    Tabs,
    Header,
  },
  watch: {
    $route: {
      handler(neVal, oldVal) {
        let path = utils.getPath(neVal.name);
        this.$store.dispatch("tabs/ADD_TAB", { neVal, oldVal });
      },
      immediate: true,
    },
  },
  computed: {
    noHeihgt() {
      return this.$store.state.menuCollapse && ["/home", "/java_home"].includes(this.$route.path);
    },
  },
};
</script>
<style lang="stylus" scoped>
.bottomC
  height: calc(100% - 56px)
.height100 {
  height: 100%;
}
.aside{
  width: auto !important;
}
.noHeight{
  display: none;
}
</style>
