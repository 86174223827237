var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "systemDialog" },
    [
      _vm.valueData.length != 0
        ? _c("div", { staticClass: "triangle" })
        : _vm._e(),
      _vm._l(_vm.valueData, function (item) {
        return _c(
          "div",
          {
            key: item.msgType,
            staticClass: "title",
            on: {
              click: function ($event) {
                return _vm.getUnreadData(item)
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(item.desc))]),
            _c("div", { class: item.recordNumber > 0 ? "count" : "" }, [
              _vm._v(
                " " +
                  _vm._s(
                    item.recordNumber > 99
                      ? "..."
                      : item.recordNumber > 0
                      ? item.recordNumber
                      : ""
                  ) +
                  " "
              ),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }