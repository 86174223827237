<template>
  <div class="systemDialog">
    <div class="triangle" v-if="valueData.length != 0"></div>
    <div v-for="item in valueData" :key="item.msgType" class="title" @click="getUnreadData(item)">
      <span>{{ item.desc }}</span>
      <div :class="item.recordNumber > 0 ? 'count' : ''">
        {{ item.recordNumber > 99 ? "..." : item.recordNumber > 0 ? item.recordNumber : "" }}
      </div>
    </div>
  </div>
</template>
<script>
import * as utils from "@/common/js/utils.js";
export default {
  props: ["unreadData"],
  data() {
    return {
      more: false,
      valueData: [],
      monitorparkCount: {},
      equimentCount: {},
      msgCount: {},
      msgtype: "",
    };
  },
  methods: {
    getUnreadData(v) {
      sessionStorage.addTab = 1;
      let data = {};
      let self = this;
      let path = '';
      if (v.desc == "监控车辆报警消息") {
        data = {
          desc: "监控车辆报警消息",
          webUri: "vehicleAlarmInfo",
        };
        self.msgtype = 2;
        path = 'SYSTEM_MANAGEMENT'
      } else if (v.desc == "设备报警消息") {
        data = {
          desc: "设备报警消息",
          webUri: "equipmentAlarmInfo",
        };
        self.msgtype = 3;
        path = 'SYSTEM_MANAGEMENT'
      } else if (v.desc == "通知消息") {
        data = {
          desc: "通知消息",
          webUri: "systemNotice",
        };
        self.msgtype = 0;
        path = 'SYSTEM_MANAGEMENT'
      } else if (v.desc == "反馈建议") {
        data = {
          desc: "反馈建议",
          webUri: "complaintsProposals",
        };
        self.msgtype = 4;
        path = 'PRODUCT_OPERATION'
      }
      const portalMenu = utils.findPortalMenu(
          this.$store.state.menu.authorityMenu,
          path
      );
      this.$store.dispatch("menu/CHANGE_MENU", portalMenu);
      this.$router.push(data.webUri);
    },
  },
  watch: {
    unreadData: {
      handler: function (ne) {
        this.valueData = ne;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="stylus" scoped>
.systemDialog {
  line-height 4px
  width 170px
  padding 0 10px
  box-shadow 0px 2px 4px 0px rgba(187,159,159,0.3)
  border-radius 6px
  box-sizing border-box
  background #fff;
  right 0
  .triangle {
    overflow hidden;
    position absolute;
    left 62%;
    transform translateX(-50%)
    top -12px
    width 0;
    height 0;
    border-top: none;
    border-left: 20px transparent solid;
    border-right: 20px transparent solid;
    border-bottom: 20px #fff solid;
  }
  .title {
    // margin-top 20px;
    display flex
    justify-content space-between
    align-items center
    color #333
    line-height 40px;
    cursor pointer
    border-bottom 1px solid #E5E5E5
    &:last-child {
      border none
    }
    .count {
      width 20px
      height 20px
      text-align center
      line-height 20px
      background #EC3131
      border-radius 50%
      font-size 12px
      color #fff
    }
  }

  .content {
    background #fff;
    .txt {
      cursor pointer;
      color #999;
      line-height 20px;
      padding-left 10px;
      border-bottom 1px solid
      .txtChild {
        color #999;
      }
    }
  }

  .footer {
    margin 0
    text-align center;
    span {
      color #0f6eff;
      cursor pointer;
    }
  }
}
</style>
