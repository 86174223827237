var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "tabs", staticClass: "tabs" },
    [
      _c(
        "el-tabs",
        {
          attrs: { closable: "", type: "card" },
          on: { "tab-remove": _vm.tabRemove, "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.currentTabs, function (item) {
          return _c("el-tab-pane", {
            key: item.path,
            attrs: { label: item.title, name: item.path },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }